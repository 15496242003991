import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
} from 'react';
import EditableTable from '../../../../../components/EditableTable/EditableTable';
import {
    GridColDef,
    GridEditSingleSelectCell,
    GridPreProcessEditCellProps,
    useGridApiRef,
} from '@mui/x-data-grid';
import TextInputColumn from '../../../../../components/EditableTable/CustomColumns/TextInputColumn/TextInputColumn';
import { ExpandedText } from '../../../../DataRequest/ExpandedText/ExpandedText';
import {
    validateGridEditorRowDisplayOrder,
    validateRequiredCell,
    validateUniqueItemName,
} from '../../../../../components/EditableTable/EditableTableValidationHelper';
import { DataTableColumn } from '../../../../../models/DataRequestHub/DataTable';
import { EditorTypeEnum } from '../ProjectEditorHost';

interface DataTableEditorInterface {
    tableColumns: DataTableColumn[];
    setTableColumns(rows: DataTableColumn[]): void;
    isLoading: boolean;
}

const DataTableEditor = forwardRef((props: DataTableEditorInterface, ref) => {
    const apiRef = useGridApiRef();
    const {
        tableColumns: tableColumns,
        setTableColumns: setTableColumns,
        isLoading,
    } = props;

    const gridTableRef = useRef(null);
    useImperativeHandle(ref, () => ({
        addRow() {
            gridTableRef.current.addRow();
        },
        validateAllRows() {
            return validateAllRows();
        },
        getEditorState() {
            return apiRef.current.state;
        },
    }));

    const columns = (): GridColDef[] => [
        {
            field: 'displayOrder',
            headerName: 'Display Order',
            minWidth: 140,
            flex: 0.25,
            type: 'number',
            cellClassName: (params) => {
                let cellClass = 'cell-input display-order';

                if (params.row.isSubQuestion) {
                    cellClass = `${cellClass} disabled`;
                }

                return cellClass;
            },
            editable: true,
            align: 'left',
            headerAlign: 'left',
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const value = params.props.value;
                const hasError = value <= 0;
                return { ...params.props, error: hasError };
            },
        },
        {
            field: 'customColumnId',
            headerName: 'C-ID',
            minWidth: 150,
            flex: 0.5,
            cellClassName: 'cell-text-input custom-column-id',
            editable: false,
            renderCell: (params) => (
                <ExpandedText
                    content={params.row.customColumnId}
                    uniqueId={`custom-column-id-input-${params?.id ?? 0}`}
                    heightToShowLinksPx={95}
                    className="MuiDataGrid-cellContent"
                />
            ),
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                return { ...params.props };
            },
            renderEditCell: (params) => <TextInputColumn {...params} />,
        },
        {
            field: 'name',
            headerName: 'Column Name',
            minWidth: 150,
            flex: 0.5,
            cellClassName: 'cell-text-input display-name',
            editable: true,
            renderCell: (params) => (
                <ExpandedText
                    content={params.row.name}
                    uniqueId={`display-name-input-${params?.id ?? 0}`}
                    heightToShowLinksPx={95}
                    className="MuiDataGrid-cellContent"
                />
            ),
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const value = params.props.value;

                const hasError = value?.trim().length <= 0;
                return { ...params.props, error: hasError };
            },
            renderEditCell: (params) => <TextInputColumn {...params} />,
        },
        {
            field: 'answerType',
            headerName: 'Answer Type',
            minWidth: 170,
            cellClassName: 'answer-type',
            flex: 0.5,
            type: 'singleSelect',
            valueOptions: ['Text'],
            editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = params.props.value?.length <= 0;
                return { ...params.props, error: hasError };
            },
            renderEditCell: (params) => (
                <GridEditSingleSelectCell {...params} />
            ),
        },
    ];

    const runValidation = (
        column: DataTableColumn,
        element: Element
    ): boolean => {
        let isValid = true;
        if (validateRequiredCell(element, '.display-name', column.name)) {
            isValid = false;
        }
        if (validateRequiredCell(element, '.answer-type', column.answerType)) {
            isValid = false;
        }

        return isValid;
    };

    const validateAllRows = () => {
        let isValid = true;
        for (let index = 0; index < tableColumns.length; index++) {
            const rowId = tableColumns[index].customColumnId;
            const element = document.querySelector('[data-id="' + rowId + '"]');
            const column = tableColumns[index];
            if (element) {
                const isColumnValid = runValidation(column, element);
                if (!isColumnValid) {
                    isValid = false;
                }
            }
        }

        apiRef.current.forceUpdate();
        if (
            !validateGridEditorRowDisplayOrder(
                tableColumns,
                apiRef.current.state,
                EditorTypeEnum.DataTable
            )
        ) {
            isValid = false;
        }

        const mappedDisplayNameColumns = tableColumns.map((m) => ({
            ...m,
            displayName: m.name,
        }));
        if (
            !validateUniqueItemName(
                mappedDisplayNameColumns,
                apiRef.current.state,
                EditorTypeEnum.DataTable
            )
        ) {
            isValid = false;
        }
        return isValid;
    };

    const updateRows = (
        newRows: (oldColumns: DataTableColumn[]) => DataTableColumn[]
    ): void => {
        const result = newRows(tableColumns);
        setTableColumns(result);
    };

    useEffect(() => {
        validateAllRows();
    }, [tableColumns]);

    return (
        <EditableTable
            editorType={EditorTypeEnum.DataTable}
            columns={columns()}
            rows={tableColumns}
            setRows={updateRows}
            isLoading={isLoading}
            fieldToFocus="displayOrder"
            ref={gridTableRef}
            gridApiRef={apiRef}
            validateAllRows={validateAllRows}
        />
    );
});

export default DataTableEditor;
