import React, { useCallback, useEffect, useRef, useState } from 'react';
import './DataTableForm.scss';
import {
    DataTable,
    DataTableRow,
} from '../../../models/DataRequestHub/DataTable';
import { GridColDef, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid';
import EditableTable from '../../../components/EditableTable/EditableTable';
import { EditorTypeEnum } from '../../Admin/DataRequest/ProjectEditorHost/ProjectEditorHost';
import { Button } from 'reactstrap';
import { useStateSelector } from '../../../store/selectors';
import createNotification from '../../../utils/createNotification';
import { CustomIdConstants } from '../../../models/DataRequestHub/CustomIdConstants';
import DataTableFormActionColumn from './DataTableFormActionColumn';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';

interface DataTableFormProps {
    projectId: number;
    dataTable: DataTable;
    selectedTableViewId: number;
    isAdminView: boolean;
    updateProjectsData(isInitialFetch?: boolean): void;
}

export const DataTableForm = (props: DataTableFormProps) => {
    const apiRef = useGridApiRef();
    const [tableData, setTableData] = useState<DataTableRow[]>([]);
    const gridTableRef = useRef(null);
    const axios = useStateSelector((s) => s.core.axios);
    const [dataTable, setDataTable] = useState<DataTable>(null);
    const [isRequestPending, setIsRequestPending] = useState(false);

    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const getCurrentTableViewColumns = () => {
        const selectedView = props.dataTable.views.find(
            (x) => x.id === props.selectedTableViewId
        );

        const columnIds =
            selectedView?.viewColumns
                .sort(
                    (a, b) => a.overridenDisplayOrder - b.overridenDisplayOrder
                )
                .map((x) => x.customColumnId) ??
            props.dataTable.columns
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map((x) => x.customColumnId);

        return columnIds
            .map((id) =>
                props.dataTable.columns.find(
                    (x) => x.customColumnId === id && x.isVisible
                )
            )
            .filter((x) => x != null);
    };

    const columns = (): GridColDef[] => {
        return getCurrentTableViewColumns().map((x) => ({
            field: x.customColumnId,
            headerName: x.name,
            minWidth: 150,
            flex: 0.5,
            cellClassName: 'cell-text-input',
            editable: true,
        }));
    };

    const updateTableData = () => {
        let rowList = props.dataTable.rows.filter(
            (x) => (x.isVisible && !x.isRemovalRequested) || props.isAdminView
        );
        const columns = getCurrentTableViewColumns();

        const result = rowList.map((row) => {
            const newRow = { ...row } as any;

            row.cells.forEach((y) => {
                const column = columns.find(
                    (x) => x.customColumnId === y.customColumnId
                );
                if (column) {
                    newRow[column.customColumnId] = y.answerText;
                }
            });
            return newRow;
        });

        setTableData(result);
    };

    const clearRowsFromCellProperties = (
        updatedRows: DataTableRow[]
    ): DataTableRow[] => {
        return updatedRows.map((updatedRow) => {
            const newCells = updatedRow.cells.map((cell) => ({
                ...cell,
                answerText:
                    (updatedRow[
                        cell.customColumnId as keyof typeof updatedRow
                    ] as string) || cell.answerText,
            }));

            return {
                id: updatedRow.id,
                customRowId: updatedRow.customRowId,
                isRemovalRequested: updatedRow.isRemovalRequested,
                isVisible: updatedRow.isVisible,
                cells: newCells,
            };
        });
    };

    const validateAllRows = () => {
        let isValid = true;

        return isValid;
    };

    const updateRows = (
        newRows: (oldRows: DataTableRow[]) => DataTableRow[]
    ): void => {
        const result = newRows(tableData);
        setTableData(result);
        debounceUpdateDataTableRows(result);
    };

    const filterUpdatedRows = (tableRows: DataTableRow[]) => {
        const updatedRows = tableRows.filter((tableRow) => {
            const originRow = dataTable.rows.find((f) => f.id === tableRow.id);

            if (originRow) {
                const hasAnyCellDifference = originRow.cells.some(
                    (originCell) => {
                        const tableCell = tableRow.cells.find(
                            (cell) => cell.id === originCell.id
                        );

                        if (tableCell) {
                            return (
                                tableCell.answerText !==
                                    originCell.answerText ||
                                originRow.isVisible !== tableRow.isVisible
                            );
                        } else {
                            return false;
                        }
                    }
                );

                return hasAnyCellDifference;
            }

            return false;
        });

        return updatedRows;
    };

    const debounceUpdateDataTableRows = (tableRows: DataTableRow[]) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            const celanRows = clearRowsFromCellProperties(tableRows);
            const updatedRows = filterUpdatedRows(celanRows);

            if (!updatedRows.length) {
                return;
            }

            axios
                .put(
                    `/api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableRows`,
                    updatedRows
                )
                .then((response) => {
                    if (response.status !== 200) {
                        const message = (response as any)?.response?.data
                            ?.detail;
                        createNotification(
                            message ?? 'An error occurred while updating row',
                            'error'
                        );
                    }

                    props.updateProjectsData();
                });
        }, 2000);
    };

    useEffect(() => {
        validateAllRows();
    }, [tableData]);

    useEffect(() => {
        if (
            JSON.stringify(dataTable?.rows) !==
                JSON.stringify(props.dataTable.rows) ||
            JSON.stringify(dataTable?.columns) !==
                JSON.stringify(props.dataTable.columns)
        ) {
            updateTableData();
            setDataTable(props.dataTable);
        }
    }, [props.dataTable]);

    const handleAddNewRow = () => {
        if (isRequestPending) return;

        setIsRequestPending(true);
        const ids = props.dataTable.rows.map((x) => x.customRowId);
        const newTableRowId =
            CustomIdConstants.getNextDataTableRowCustomId(ids);
        axios
            .post(
                `/api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableRows`,
                { customDataTableRowId: newTableRowId }
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    const message = (response as any)?.response?.data?.detail;
                    createNotification(
                        message ?? 'An error occurred while adding row',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsRequestPending(false);
            });
    };

    const renderAddNewRow = () => (
        <ButtonLoader
            onClick={handleAddNewRow}
            buttonText={'+ Add New Row'}
            className={'btn btn-primary'}
            loaderButtonText={''}
            isLoading={isRequestPending}
            disabled={isRequestPending}></ButtonLoader>
    );

    const getRowId = (row: GridValidRowModel) => row.customRowId;

    const customActionColumn = DataTableFormActionColumn({
        setRows: updateRows,
        gridApiRef: apiRef,
        getRowId: getRowId,
        projectId: props.projectId,
        dataTableId: props.dataTable.id,
        updateProjectsData: props.updateProjectsData,
        isAdmin: props.isAdminView,
    });

    return (
        <div className="data-table-view-host">
            <EditableTable
                editorType={EditorTypeEnum.DataTableData}
                columns={columns()}
                rows={tableData}
                setRows={updateRows}
                fieldToFocus="displayOrder"
                ref={gridTableRef}
                gridApiRef={apiRef}
                validateAllRows={validateAllRows}
                customActionColumn={customActionColumn}
                disableVisibilityColumn={!props.isAdminView}
            />
            {renderAddNewRow()}
        </div>
    );
};
